<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-19 14:38:06
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-14 15:40:28
 * @Description  : 设备信息详情
-->
<template>
  <div class="container">
    <div class="top-box">
      <div class="left-box">
        <div class="record-style">
          <div v-if="deviceBaseInfo.networkStatus == 0" class="status-box-gray">
            {{ $t("offline") }}
          </div>
          <div
            v-if="deviceBaseInfo.networkStatus == 1"
            class="status-box-green"
          >
            {{ $t("online") }}
          </div>
          <span
            class="font-heavy"
            :style="
              deviceBaseInfo.networkStatus == 1
                ? 'color:black'
                : 'color:#929293'
            "
            >{{ deviceBaseInfo.name }}</span
          >
          <img
            src="../../assets/ic_edit.png"
            alt=""
            @click="changeLoggerName()"
          />
        </div>
        <!-- Online/Offline/Recording/Stopped(多次性设备1停止，可以再次开始)/End(一次性设备0停止就是这个状态) -->
        <div>{{ $t("device_id") }}: {{ deviceBaseInfo.deviceId }}</div>
        <div class="status-box">
          <div
            class="point"
            :style="
              deviceBaseInfo.status == 0 ||
              deviceBaseInfo.status == 3 ||
              deviceBaseInfo.status == 4 ||
              deviceBaseInfo.status == 9
                ? 'background: #ed6e36;'
                : 'background: #02C06F;'
            "
          ></div>
          <span v-if="deviceBaseInfo.status == 0">
            {{ $t("not_started") }}
          </span>
          <span v-if="deviceBaseInfo.status == 1">{{ $t("recording") }}</span>
          <span v-if="deviceBaseInfo.status == 2">
            {{ $t("delay_recording") }}
          </span>
          <span
            v-if="deviceBaseInfo.status == 3 && deviceBaseInfo.cycleInd == 0"
            >{{ $t("ended") }}</span
          >
          <!-- && deviceBaseInfo.cycleInd == 0 -->
          <span
            v-if="deviceBaseInfo.status == 4 "
            >{{ $t("stop_record") }}</span
          >
          <span v-if="deviceBaseInfo.status == 9">{{ $t("discard") }}</span>
        </div>
        <div class="time-box">
          <span>
            {{ $t("started_at") }}:
            {{
              deviceBaseInfo.startTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
          </span>
          <span>
            {{ $t("end_at") }}:
            {{
              deviceBaseInfo.endTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
          </span>
        </div>
        <!-- cursor: not-allowed -->
        <!-- 显示开启：0，4  显示关闭：1，2 -->
        <div class="function-box">
          <div
            v-if="
              deviceBaseInfo.status == 0 ||
                deviceBaseInfo.status == 3 ||
                deviceBaseInfo.status == 4
            "
            class="function-item"
            @click="startRecording"
            :style="
              (cycleInd == 1 && deviceBaseInfo.status == 0) ||
              deviceBaseInfo.status == 3 ||
              (cycleInd == 1 && deviceBaseInfo.status == 4)
                ? 'cursor: pointer'
                : 'cursor: not-allowed'
            "
          >
            <img
              :src="
                (cycleInd == 1 && deviceBaseInfo.status == 0) ||
                deviceBaseInfo.status == 3 ||
                (cycleInd == 1 && deviceBaseInfo.status == 4)
                  ? icStart
                  : icStartGray
              "
              alt=""
              srcset=""
            />
            <span
              :style="
                (cycleInd == 1 && deviceBaseInfo.status == 0) ||
                deviceBaseInfo.status == 3 ||
                (cycleInd == 1 && deviceBaseInfo.status == 4)
                  ? 'color:#1a72f4'
                  : 'color:#c0c2cb'
              "
              >{{ $t("start_record") }}</span
            >
          </div>

          <div
            v-else-if="
              deviceBaseInfo.status == 1 ||
                deviceBaseInfo.status == 2 ||
                deviceBaseInfo.status == 3 ||
                deviceBaseInfo.status == 9
            "
            class="function-item"
            @click="stopRecording"
            :style="
              (cycleInd == 1 && deviceBaseInfo.status == 1) ||
              (cycleInd == 1 && deviceBaseInfo.status == 2) ||
              (cycleInd == 1 && deviceBaseInfo.status == 3) ||
              (cycleInd == 1 && deviceBaseInfo.status == 9)
                ? 'cursor: pointer'
                : 'cursor: not-allowed'
            "
          >
            <img
              :src="
                (cycleInd == 1 && deviceBaseInfo.status == 1) ||
                (cycleInd == 1 && deviceBaseInfo.status == 2) ||
                (cycleInd == 1 && deviceBaseInfo.status == 3) ||
                (cycleInd == 1 && deviceBaseInfo.status == 9)
                  ? icStop
                  : icStopGray
              "
              alt=""
              srcset=""
            />
            <span
              :style="
                (cycleInd == 1 && deviceBaseInfo.status == 1) ||
                (cycleInd == 1 && deviceBaseInfo.status == 2) ||
                (cycleInd == 1 && deviceBaseInfo.status == 3) ||
                (cycleInd == 1 && deviceBaseInfo.status == 9)
                  ? 'color:#1a72f4'
                  : 'color:#c0c2cb'
              "
              >{{ $t("stop_record") }}</span
            >
          </div>

          <div v-if="isDelete" class="function-item">
            <img src="../../assets/ic_delete_gray.png" alt="" srcset="" />
            <span :style="isDelete ? 'color:#c0c2cb' : 'color:#ed6e36'">{{
              $t("delete")
            }}</span>
          </div>

          <div
            v-else-if="!isDelete"
            class="function-item"
            @click="removeDevice"
          >
            <img src="../../assets/ic_delete_orange.png" alt="" srcset="" />
            <span :style="isDelete ? '' : 'color:#ed6e36'">{{
              $t("delete")
            }}</span>
          </div>
          <!-- pointer-events: none -->
          <div
            v-if="isDiscard"
            class="function-item"
            style="cursor: not-allowed"
          >
            <img src="../../assets/ic_discard_gray.png" alt="" srcset="" />
            <span :style="isDiscard ? 'color:#c0c2cb' : 'color:#ed6e36'">{{
              $t("deprecated")
            }}</span>
          </div>

          <div
            v-else-if="!isDiscard"
            class="function-item"
            @click="discardDevice"
          >
            <img src="../../assets/ic_discard_orange.png" alt="" srcset="" />
            <span :style="!isDiscard ? 'color:#ed6e36' : 'color:#c0c2cb'">{{
              $t("deprecated")
            }}</span>
          </div>

          <div v-if="isShare" class="function-item" @click="shareLogger()">
            <img src="../../assets/ic_share_gray.png" alt="" srcset="" />
            <span :style="isShare ? 'color:#c0c2cb' : 'color:#ed6e36'">{{
              $t("cancel_share")
            }}</span>
          </div>

          <div
            v-else-if="!isShare"
            class="function-item"
            @click="shareLogger()"
          >
            <img src="../../assets/ic_share.png" alt="" srcset="" />
            <span :style="!isShare ? '' : 'color:#c0c2cb'">{{
              $t("share")
            }}</span>
          </div>
        </div>

        <div class="measurements">
          <el-row class="measurements-title">
            <el-col :span="8"></el-col>
            <el-col :span="8"
              ><span class="label">{{
                $t("latest_measurements")
              }}</span></el-col
            >
          </el-row>

          <div class="measurements-box">
            <div class="measurements-up">
              <div class="left1">
                <span
                  class="label"
                  :style="deviceDataInfo.temp ? '' : 'color:#d9d9d9'"
                  >{{ $t("temperature") }}</span
                >
                <div class="left1_1">
                  <img
                    :src="
                      deviceDataInfo.temp ? icTemperature : icTemperatureGray
                    "
                    alt=""
                  />
                  <span
                    class="value font-heavy"
                    v-if="deviceDataInfo.temp"
                    :style="deviceDataInfo.temp ? '' : 'color:#d9d9d9'"
                    >{{
                      Number(deviceDataInfo.temp)
                        | convertTemperature(setSelectedTempType)
                    }}{{ setSelectedTempType }}</span
                  >
                  <span class="value" v-else-if="!deviceDataInfo.temp">-</span>
                </div>
                <span style="color: #d9d9d9">{{
                  deviceDataInfo.refreshDate
                    | formatDateWithTimeZone(
                      setSelectedTimeZone,
                      setSelectedLanguage
                    )
                }}</span>
              </div>
              <div class="left2">
                <span
                  class="label"
                  :style="deviceDataInfo.wetness ? '' : 'color:#d9d9d9'"
                  >{{ $t("humidity") }}</span
                >
                <div class="left2_2">
                  <img
                    :src="deviceDataInfo.wetness ? icHumidity : icHumidityGray"
                    alt=""
                  />
                  <span
                    class="value font-heavy"
                    :style="deviceDataInfo.wetness ? '' : 'color:#d9d9d9'"
                    >{{ deviceDataInfo.wetness || "-" }}</span
                  >
                </div>
              </div>
              <div class="right1">
                <span
                  class="label"
                  :style="deviceDataInfo.temp1 ? '' : 'color:#d9d9d9'"
                  >{{ $t("temperature") }}</span
                >
                <div class="right1_1">
                  <img
                    :src="
                      deviceDataInfo.temp1 ? icTemperature : icTemperatureGray
                    "
                    alt=""
                  />
                  <span class="value font-heavy" v-if="deviceDataInfo.temp1"
                    >{{
                      Number(deviceDataInfo.temp1)
                        | convertTemperature(setSelectedTempType)
                    }}{{ setSelectedTempType }}</span
                  >
                  <span
                    class="value font-heavy"
                    :style="deviceDataInfo.temp1 ? '' : 'color:#d9d9d9'"
                    v-else-if="!deviceDataInfo.temp1"
                    >-</span
                  >
                </div>
                <span
                  v-if="deviceDataInfo.temp1 && deviceDataInfo.wetness1"
                  style="color: #d9d9d9"
                  >{{
                    deviceDataInfo.refreshDate
                      | formatDateWithTimeZone(
                        setSelectedTimeZone,
                        setSelectedLanguage
                      )
                  }}</span
                >
                <span
                  style="color: #d9d9d9"
                  v-if="!deviceDataInfo.temp1 && !deviceDataInfo.wetness1"
                  >{{ $t("no_external_sensor") }}</span
                >
              </div>
              <div class="right2">
                <span
                  class="label"
                  :style="deviceDataInfo.wetness1 ? '' : 'color:#d9d9d9'"
                  >{{ $t("humidity") }}</span
                >
                <div class="right2_2">
                  <img
                    :src="deviceDataInfo.wetness1 ? icHumidity : icHumidityGray"
                    alt=""
                  />

                  <span
                    class="value font-heavy"
                    :style="deviceDataInfo.wetness1 ? '' : 'color:#d9d9d9'"
                    >{{ deviceDataInfo.wetness1 || "-" }}</span
                  >
                </div>
              </div>
            </div>
            <div class="measurements-down">
              <div class="left" v-if="deviceDataInfo.light">
                <span
                  class="label"
                  :style="deviceDataInfo.light.value ? '' : 'color:#d9d9d9'"
                  >{{ $t("light_intensity") }}</span
                >
                <div class="left_1">
                  <img
                    :src="deviceDataInfo.light.value ? icLight : icLightGray"
                    alt=""
                  />
                  <span
                    class="value font-heavy"
                    :style="deviceDataInfo.light.value ? '' : 'color:#d9d9d9'"
                    >{{ deviceDataInfo.light.value || "-" }}</span
                  >
                </div>
                <span style="color: #d9d9d9">{{
                  deviceDataInfo.light.timestamp
                    | formatDateWithTimeZone(
                      setSelectedTimeZone,
                      setSelectedLanguage
                    )
                }}</span>
              </div>

              <div class="right" v-if="deviceDataInfo.shock">
                <span
                  class="label"
                  :style="deviceDataInfo.shock.value ? '' : 'color:#d9d9d9'"
                  >{{ $t("vibrate") }}</span
                >
                <div class="right_1">
                  <img
                    :src="
                      deviceDataInfo.shock.value ? icVibrate : icVibrateGray
                    "
                    alt=""
                  />
                  <span
                    class="value font-heavy"
                    :style="deviceDataInfo.shock.value ? '' : 'color:#d9d9d9'"
                    >{{ deviceDataInfo.shock.value || "-" }}</span
                  >
                </div>
                <span style="color: #d9d9d9">{{
                  deviceDataInfo.shock.timestamp
                    | formatDateWithTimeZone(
                      setSelectedTimeZone,
                      setSelectedLanguage
                    )
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right-box">
        <div class="operate-box">
          <div class="btn-box">
            <el-button
              class="btn-style"
              :class="{ 'limits-1': limits === 1, 'limits-0': limits === 0 }"
              :disabled="limits === 0"
              @click="addToOrganization"
            >
              {{ $t("add_to_organization") }}
            </el-button>
            <!-- <el-button
              class="btn-style"
              :class="{ 'limits-1': limits === 1, 'limits-0': limits === 0 }"
              :disabled="limits === 0"
              @click="addToScene"
            >
              {{ $t("add_to_scene") }}
            </el-button> -->
            <el-button
              class="btn-style"
              :class="{ 'limits-1': limits === 1, 'limits-0': limits === 0 }"
              :disabled="limits === 0"
              @click="transferDevice"
            >
              {{ $t("transfer_device") }}
            </el-button>
          </div>
        </div>
        <div class="table-container">
          <table border="0" cellspacing="0" style="border-collapse: collapse">
            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("logger_name") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.name
                }}<img
                  src="@/assets/ic_edit.png"
                  alt=""
                  @click="changeLoggerName()"
                />
              </td>
            </tr>

            <tr>
              <td class="white-cell label font-heavy">{{ $t("remark") }}</td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.remark || "-" }}
                <img
                  src="@/assets/ic_edit.png"
                  alt=""
                  @click="changeLoggerRemark()"
                />
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">ID</td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                #{{ deviceBaseInfo.deviceId }}
              </td>
            </tr>

            <tr>
              <td class="white-cell label font-heavy">{{ $t("model") }}</td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.model }}
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("measurement_interval") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.recordingMin | UploadInterval }}
              </td>
            </tr>

            <tr>
              <td class="white-cell label font-heavy">
                {{ $t("upload_interval") }}
              </td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.uploadMin | UploadInterval }}
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("teams_with_access") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ orgInfo.name || "-"
                }}<img
                  src="@/assets/ic_edit.png"
                  alt=""
                  @click="teamsAccess()"
                />
              </td>
            </tr>
            <tr>
              <td
                class="white-cell label font-heavy"
                style="border-bottom: none !important"
              >
                {{ $t("shipment") }}
              </td>
              <td
                class="white-cell"
                style="
                  width: 250px;
                  border-right: none !important;
                  border-bottom: none !important;
                "
              >
                <div class="shipment-detail" v-if="deviceBaseInfo.shipmentName">
                  <div class="btn-box" @click="goShipmentDetail">
                    {{ deviceBaseInfo.shipmentName }}
                  </div>
                </div>

                <div
                  v-if="!deviceBaseInfo.shipmentName"
                  class="shipment-operate"
                >
                  <span>{{ $t("not_in_a_shipment") }}</span>
                  <div class="btn-box" @click="newShipment">
                    +{{ $t("new_shipment") }}
                  </div>
                  <div class="btn-box" @click="attachToShipment">
                    {{ $t("attach_to_existing_shipment") }}
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="echart-box">
      <span class="font-heavy">{{ $t("historical_data_chart") }}</span>
      <div class="zoom-style">
        <div class="left">
          <div>{{ $t("zoom") }}</div>
          <div class="tab-box">
            <div
              class="tab-item"
              :class="isActive == 1 ? 'is-active' : ''"
              @click="clickTab(1)"
            >
              {{ $t("1_day") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 2 ? 'is-active' : ''"
              @click="clickTab(2)"
            >
              {{ $t("1_week") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 3 ? 'is-active' : ''"
              @click="clickTab(3)"
            >
              {{ $t("1_month") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 4 ? 'is-active' : ''"
              @click="clickTab(4)"
            >
              {{ $t("1_year") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 5 ? 'is-active' : ''"
              @click="clickTab(5)"
            >
              {{ $t("all") }}
            </div>

            <div class="zoom-box">
              {{ $t("select_chart_height") }}:
              <el-select v-model="zoomOptionsValue" @change="changeZoomOption">
                <el-option
                  v-for="item in zoomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="date-tip">{{ $t("from") }}</div>
          <el-date-picker
            v-model="startDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('start_date')"
            @change="onChangeStart"
          >
          </el-date-picker>
          <div class="date-tip">{{ $t("to") }}</div>
          <el-date-picker
            v-model="endDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('end_date')"
            @change="onChangeEnd"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="chart">
        <StackedAreaChart ref="chartRef" class="chart-item"></StackedAreaChart>

        <div
          class="additional-div"
          :style="{ 'margin-right': `calc(100% - ${chartWidth})` }"
        >
          <img src="../../assets/ic_echart_alert.png" alt="" />
          <div v-if="!this.tempConfig && !this.wetnessConfig" class="all-null">
            <span> {{ $t("no_alert_rules") }}</span>
          </div>
          <div v-if="this.tempConfig || this.wetnessConfig" class="not-null">
            <div class="high">High <span> - -</span></div>
            <div class="low">Low <span> - -</span></div>
          </div>
        </div>
      </div>
      <el-button
        type="primary"
        @click="downloadExcel"
        style="margin-top: 10px"
        >{{ $t("view_as_list_or_download_excel") }}</el-button
      >
    </div>

    <div class="rules-box">
      <div class="rules-title">
        <div class="left">
          <span class="font-heavy"> {{ $t("alert_rules_key") }}</span>
          <!-- （1）没有报警规则，且不可设置，没有设置报警规则 且不允许设置新的报警规则时 -->
          <div v-if="form.detailList.length <= 0 && rulesEnabled == 0">
            <span class="font-light"> {{ $t("no_alert_rules") }}.</span>
            <span class="font-light"> {{ $t("logger_started_tip") }}</span>
          </div>
          <!-- 没有报警规则，可以设置新的 -->
          <div v-if="form.detailList.length <= 0 && rulesEnabled == 1">
            <span class="font-light"> {{ $t("no_alert_rules_tip") }}</span>
          </div>

          <span class="font-light" v-if="form.detailList.length > 0">
            {{ $t("alert_rules_tip_key") }}</span
          >
        </div>
        <div class="right">
          <el-button
            type="primary"
            @click="selectExistedRules"
            :disabled="rulesEnabled == 0 ? true : false"
            :style="
              rulesEnabled == 0 ? ' background: #1a72f4;opacity: 0.8;' : ''
            "
            >{{ $t("select_existed_rules") }}</el-button
          >
          <el-button
            type="primary"
            @click="newRules"
            :disabled="rulesEnabled == 0 ? true : false"
            :style="
              rulesEnabled == 0 ? ' background: #1a72f4;opacity: 0.8;' : ''
            "
            >+{{ $t("new_rules") }}</el-button
          >
        </div>
      </div>

      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item
          v-for="(rule, index) in form.detailList"
          :key="rule.key"
          :prop="'detailList.' + index + '.value'"
        >
          <div class="rule-box">
            <div class="selects-container">
              <img
                v-if="rulesActived == 0"
                src="@/assets/ic_rule_alert.png"
                alt=""
              />
              <el-select v-model="rule.type" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmType"
                  :key="item.value"
                ></el-option>
              </el-select>
              <el-select v-model="rule.source" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="(item, index) in alarmSensor"
                  :key="index"
                ></el-option>
              </el-select>
              <!-- <el-select v-model="rule.conditions" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmConditions"
                  :key="item.value"
                ></el-option>
              </el-select> -->

              <!-- <el-input
                v-model="rule.value"
                class="input-item"
                disabled
              ></el-input> -->

              <el-input
                class="input-item custom-placeholder"
                placeholder="less than"
                disabled
              >
              </el-input>

              <el-input
                v-model.number="rule.min"
                class="input-item"
                disabled
              ></el-input>
              OR
              <el-input
                class="input-item custom-placeholder"
                placeholder="more than"
                disabled
              >
              </el-input>

              <el-input
                v-model.number="rule.max"
                class="input-item"
                disabled
              ></el-input>
              <el-input
                v-model="rule.unit"
                class="input-item"
                disabled
                v-if="!showTemperatureUnit(rule)"
              >
              </el-input>
              <div v-if="showDurationSelector(rule)">
                For more than
                <el-input
                  v-model.number="rule.duration"
                  disabled
                  class="input-item"
                ></el-input>
                <el-input
                  placeholder="Minutes"
                  class="input-item custom-placeholder"
                  disabled
                  style="margin-left: 8px"
                ></el-input>
              </div>
            </div>
            <img
              v-if="rulesEnabled != 0"
              @click.prevent="removeRule(rule)"
              src="../../assets/ic_cross.png"
              alt=""
            />
          </div>
        </el-form-item>
      </el-form>
      <!-- 0:未生效 1：已生效 9：无告警规则，显示同1已生效 -->
      <div v-if="rulesActived == 0">{{ $t("alert_rule_not_take_effect") }}</div>
      <div v-if="rulesActived == 1 || rulesActived == 9">
        {{ $t("alert_rule_take_effect") }}
      </div>

      <!-- <div>{{ $t("alert_rules_tip") }}</div> -->
    </div>

    <div class="alert-box">
      <div class="alert-title">
        <span class="font-heavy">{{ $t("alert") }}</span>
        <el-button type="primary" @click="setRead">{{
          $t("mark_selected_alerts_as_checked")
        }}</el-button>
      </div>
      <!-- <div class="table-title">{{ $t("showing") }}</div> -->

      <el-table
        :data="alarmList"
        style="width: 100%"
        stripe
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        ref="alarmTable"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleAlarmSelectionChange"
        :empty-text="$t('no_data')"
      >
        <el-table-column prop="content" :label="$t('cause')">
          <template v-slot:default="scope">
            <span> {{ scope.row.content || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" :label="$t('alarm_time')">
          <template v-slot:default="scope">
            <span>
              {{
                scope.row.createdDate
                  | formatDateWithTimeZone(
                    setSelectedTimeZone,
                    setSelectedLanguage
                  )
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="comment" :label="$t('comments')">
          <template v-slot:default="scope">
            <div class="comment-box" v-if="!scope.row.comment">
              <el-button
                type="primary"
                class="add-comment"
                @click="addComment(scope.row)"
                style="
                  background: transparent;
                  border: 1px solid transparent;
                  color: #1a72f4;
                  text-decoration: underline;
                "
              >
                +{{ $t("add_comment") }}</el-button
              >
            </div>
            <div v-else-if="scope.row.comment">{{ scope.row.comment }}</div>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55"> </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: center"
        background
        @current-change="handleCurrentChange"
        :current-page="Number(this.pageNum)"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="Number(this.pageSize)"
        layout="total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <div class="statistics-box">
      <span class="font-heavy">{{ $t("statistical_data") }}</span>
      <table border="0" cellspacing="0" style="border-collapse: collapse">
        <tr>
          <td class="white-cell">
            <div></div>
          </td>
          <td class="white-cell font-heavy">
            <div>{{ $t("mins") }}</div>
          </td>
          <td class="white-cell font-heavy">
            <div>{{ $t("maxes") }}</div>
          </td>
          <td class="white-cell font-heavy">
            <div>{{ $t("averages") }}</div>
          </td>
        </tr>
        <tr v-for="(item, index) in totalList" :key="index">
          <td class="white-cell font-heavy">
            <div v-if="item.name == 'Temperature'">{{ $t("temperature") }}</div>
            <div v-else-if="(item.name = 'Wetness')">{{ $t("humidity") }}</div>
          </td>
          <td class="white-cell">
            <div v-if="index == 0">
              {{ Number(item.min) | convertTemperature(setSelectedTempType)
              }}{{ setSelectedTempType }}
            </div>
            <div v-else-if="index == 1">{{ item.min }}</div>
          </td>
          <td class="white-cell">
            <div v-if="index == 0">
              {{ Number(item.max) | convertTemperature(setSelectedTempType)
              }}{{ setSelectedTempType }}
            </div>
            <div v-else-if="index == 1">{{ item.max }}</div>
          </td>
          <td class="white-cell">
            <div v-if="index == 0">
              {{ Number(item.avg) | convertTemperature(setSelectedTempType)
              }}{{ setSelectedTempType }}
            </div>
            <div v-else-if="index == 1">{{ item.avg }}</div>
          </td>
        </tr>
      </table>
    </div>

    <div class="summary-box">
      <span class="font-heavy">{{ $t("summary") }}</span>
      <table border="0" cellspacing="0" style="border-collapse: collapse">
        <tr>
          <td class="white-cell">
            <div class="font-heavy">
              {{ $t("duration_of_recording") }}
            </div>
            <div>{{ summaryDuration || "-" }}</div>
          </td>
          <td class="white-cell">
            <div class="font-heavy">{{ $t("number_of_records") }}</div>
            <div>{{ summaryRecordTotal }}</div>
          </td>
          <td class="white-cell">
            <div class="font-heavy">{{ $t("total_number_of_alarms") }}</div>

            <div>{{ summaryAlarmTotal }}</div>
          </td>
        </tr>
      </table>
    </div>
    <ShareLoggerDialog
      ref="shareRef"
      @isShare="handleIsShare"
    ></ShareLoggerDialog>
    <ChangeLoggerNameDialog
      ref="nameRef"
      @refresh="getDetailInfo"
    ></ChangeLoggerNameDialog>
    <ChangeLoggerRemarkDialog
      ref="remarkRef"
      @refresh="getDetailInfo"
    ></ChangeLoggerRemarkDialog>
    <!-- <ChangeAlarmConditionsDialog ref="editRef" @refresh="getAlarmRules">
    </ChangeAlarmConditionsDialog> -->
    <TransferDeviceDialog ref="transferRef"></TransferDeviceDialog>
    <!-- <AddDeviceToSceneDialog ref="deviceToSceneRef"></AddDeviceToSceneDialog> -->
    <AddDeviceToOrganDialog ref="deviceToOrganRef"></AddDeviceToOrganDialog>
    <AddDeviceToTeamsDialog
      ref="deviceToTeamsRef"
      @ok="getDetailInfo"
    ></AddDeviceToTeamsDialog>

    <AddCommentDialog
      ref="commentRef"
      @refresh="getAlarmList"
    ></AddCommentDialog>

    <SelectExistedRulesDialog
      ref="existedRulesRef"
      @refresh="getAlarmRules"
    ></SelectExistedRulesDialog>
    <ShowCanAddShipmentListDialog
      ref="shipmentRef"
      @refresh="getDetailInfo"
    ></ShowCanAddShipmentListDialog>
  </div>
</template>

<script>
import ShareLoggerDialog from "../logger/modal/ShareLoggerDialog.vue";
import ChangeLoggerNameDialog from "./modal/ChangeLoggerNameDialog.vue";
import ChangeLoggerRemarkDialog from "./modal/ChangeLoggerRemarkDialog.vue";
import StackedAreaChart from "../components/StackedAreaChart.vue";
// import ChangeAlarmConditionsDialog from "./modal/ChangeAlarmConditionsDialog.vue";
import TransferDeviceDialog from "./modal/TransferDeviceDialog.vue";
// import AddDeviceToSceneDialog from "./modal/AddDeviceToSceneDialog.vue";
import AddDeviceToOrganDialog from "./modal/AddDeviceToOrganDialog.vue";
import AddCommentDialog from "./modal/AddCommentDialog.vue";
import SelectExistedRulesDialog from "./modal/SelectExistedRulesDialog.vue";
import AddDeviceToTeamsDialog from "./modal/AddDeviceToTeamsDialog.vue";
import ShowCanAddShipmentListDialog from "../logger/modal/ShowCanAddShipmentListDialog.vue";

import moment from "moment";

import {
  startRecordingApi,
  discardDeviceApi,
  setAlarmReadApi,
  getDetailInfoApi,
  getDetailLineApi,
  getDetailSummaryApi,
  getAlarmRulesApi,
  getAlarmListApi,
  stopRecordingApi,
  removeDetailDeviceApi,
  selectApi,
  deleteAlarmSettingApi,
} from "@/api/api.js";

import _ from "lodash";

export default {
  name: "LoggerDetail",
  data() {
    return {
      networkStatus: 0,
      zoomValue: 30,
      zoomOptions: [
        { value: "1", label: "1x" },
        { value: "2", label: "2x" },
        { value: "3", label: "3x" },
      ],
      zoomOptionsValue: "1",
      chartHeight: "500px",
      deviceBaseInfo: {},
      deviceDataInfo: {},
      form: { detailList: {} },
      sceneInfo: { name: "" },
      orgInfo: {},
      option: {
        color: ["#76BFF5", "#50D69C"],
        legend: ["", ""],
        xData: [],
        unit: "℃",
        axisLabel: {
          show: true, // 是否显示刻度标签，默认显示
          interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效；
        },
        yAxis: [{ name: "", min: 0, max: 0 }, { name: "" }],
        y2Height: 70,
        y1Name: "",
        x1Name: "",
        lineSeries: [
          {
            name: "",
            type: "line",
            smooth: true,
            areaStyle: {
              opacity: 0.1,
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  position: "top",
                },
              },
            },
            data: [],
            markLine: {
              data: [],
            },
          },
          {
            name: "",
            type: "line",
            smooth: true,
            areaStyle: {
              opacity: 0.1,
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  position: "top",
                },
              },
            },
            data: [],
            markLine: {
              data: [],
            },
          },
        ],
      },
      alarmList: [],
      isActive: 1,
      startDate: "",
      endDate: "",
      deviceIds: [],
      alarmSelection: [],
      lineDataList: [],
      type: 1, //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
      summaryAlarmTotal: 0,
      summaryDuration: "",
      summaryRecordTotal: 0,
      totalList: [],
      isRecord: false,
      isDelete: false,
      isDiscard: false,
      isShare: false,
      rulesActived: 0, // 0:未生效 1：已生效 9：无告警规则，显示同1已生效
      alarmType: [],
      alarmSensor: [],
      alarmConditions: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      timezone: 0,
      isChina: true,
      limits: "",
      deviceId: "",
      cycleInd: 0, //是否重复使用设备标识 1:是，0:否 0时告警规则不允许编辑
      rulesEnabled: 0,
      icStart: require("@/assets/ic_start.png"),
      icStartGray: require("@/assets/ic_start_gray.png"),
      icStop: require("@/assets/ic_stop.png"),
      icStopGray: require("@/assets/ic_stop_gray.png"),

      icTemperatureGray: require("@/assets/ic_temperature_gray.png"),
      icTemperature: require("@/assets/ic_temperature.png"),
      icHumidityGray: require("@/assets/ic_humidity_gray.png"),
      icHumidity: require("@/assets/ic_humidity.png"),
      icLightGray: require("@/assets/ic_light_gray.png"),
      icLight: require("@/assets/ic_light.png"),
      icVibrateGray: require("@/assets/ic_vibrate_gray.png"),
      icVibrate: require("@/assets/ic_vibrate.png"),
      startTime: "",
      endTime: "",
      tempConfig: {},
      wetnessConfig: {},
    };
  },
  methods: {
    shareLogger() {
      this.$refs.shareRef.showDialog(this.deviceId);
    },
    changeLoggerName() {
      this.$refs.nameRef.showDialog(this.deviceId);
    },
    changeLoggerRemark() {
      this.$refs.remarkRef.showDialog(this.deviceId);
    },
    startRecording() {
      if (this.cycleInd === 0) {
        return;
      }
      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
      };
      startRecordingApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("start_recording"));
        // this.isRecord = false;
        this.getDetailInfo();
      });
    },

    discardDevice() {
      this.$alert(this.$t("discard_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
      }).then(() => {
        let params = {
          deviceId: this.deviceId,
          timezone: this.timezone,
        };
        discardDeviceApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("operation_successful"));
          this.isDiscard = !this.isDiscard;
        });
      });
    },

    removeDevice() {
      this.$alert(this.$t("delete_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
      }).then(() => {
        let params = {
          deviceId: this.deviceId,
          timezone: this.timezone,
        };
        removeDetailDeviceApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("remove_logger_success"));
          this.isDelete = !this.isDelete;
          // this.dialogFormVisible = false;
          this.goPages("/home/board");
        });
      });
    },
    stopRecording() {
      if (this.cycleInd === 0) {
        return;
      }
      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
      };
      stopRecordingApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("stop_record"));
        // this.dialogFormVisible = false;
        // this.isRecord = true;
        this.getDetailInfo();
      });
    },

    clickTab(idx) {
      //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
      this.isActive = idx;
      console.log("clickTab---idx---" + idx);
      switch (idx) {
        case 1:
          this.type = 1;
          console.log("日", moment().format("YYYY-MM-DD"));
          this.startDate = moment().format("YYYY-MM-DD");
          this.endDate = moment().format("YYYY-MM-DD");
          break;
        case 2:
          this.type = 2;
          console.log(
            "周",
            moment()
              .startOf("isoWeek")
              .format("YYYY-MM-DD")
          );
          this.startDate = moment()
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
          break;
        case 3:
          this.type = 3;
          console.log(
            "月",
            moment()
              .startOf("month")
              .format("YYYY-MM-DD")
          );
          this.startDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
        case 4:
          this.type = 4;
          console.log(
            "年",
            moment()
              .startOf("year")
              .format("YYYY-MM-DD")
          );
          this.startDate = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .endOf("year")
            .format("YYYY-MM-DD");
          break;
        case 5:
          this.type = 9;
          this.startDate = "";
          this.endDate = "";
          break;
      }

      this.getDetailLine();
    },
    onChangeStart(val) {
      console.log("val: ", val);
      this.startDate = val;
      this.getDetailLine();
    },

    onChangeEnd(val) {
      console.log("val: ", val);
      this.endDate = val;
      this.getDetailLine();
    },

    transferDevice() {
      this.$refs.transferRef.showDialog(this.deviceId);
    },
    addComment(item) {
      this.$refs.commentRef.showDialog(item);
    },

    teamsAccess() {
      this.$refs.deviceToTeamsRef.showDialog(this.deviceId, this.orgInfo.orgId);
    },
    getAlarmList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        timezone: this.timezone,
        startDate: this.startDate,
        endDate: this.endDate,
        deviceId: this.deviceId,
      };
      getAlarmListApi(params).then((r) => {
        console.log("获取告警详情列表=====", r);
        this.alarmList = r.data.records;
        this.total = r.data.total;
        console.log("告警详情列表数据条数: ", r.data.length);
      });
    },

    getDetailSummary() {
      getDetailSummaryApi({ deviceId: this.deviceId }).then((r) => {
        console.log("获取温湿度统计以及summary信息=====", r);
        this.totalList = r.data.totalList;
        this.summaryAlarmTotal = r.data.summaryAlarmTotal;
        this.summaryDuration = r.data.summaryDuration;
        this.summaryRecordTotal = r.data.summaryRecordTotal;
      });
    },

    getDetailLine() {
      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type, //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
        startDateTimestamp: 0,
      };
      getDetailLineApi(params).then((r) => {
        console.log("获取折线图数据=====", r);
        this.lineDataList = r.data.lineDataList || [];
        console.log("this.lineDataList: ", this.lineDataList);
        // if (this.lineDataList.length <= 0) {
        //   this.$message.success(this.$t("no_data"));
        // }
        let gatherDateArr = [];
        this.lineDataList.forEach((element) => {
          console.log("element: ", JSON.stringify(element));
          gatherDateArr.push(
            this.$options.filters.formatDateWithTimeZone(
              this.convertToTimestamp(element.gatherDate),
              this.setSelectedTimeZone,
              this.setSelectedLanguage
            )
          );
        });

        this.option.topPosition = 15;
        this.option.xData = gatherDateArr;
        this.option.yAxis[0].name = this.$t("temperature");
        this.option.yAxis[1].name = this.$t("humidity");
        this.option.yAxis[0].yAxisIndex = 0;
        this.option.yAxis[1].yAxisIndex = 1;

        this.option.legend[0] =
          this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
        this.option.legend[1] = this.$t("humidity") + "(%)";

        //处理温度
        let tempArr = [];
        // 收集温度数据并应用过滤器
        tempArr = this.lineDataList.map((element) => {
          return this.$options.filters.convertTemperature(
            Number(element.temp),
            this.setSelectedTempType
          );
        });
        console.log("tempArr: ", tempArr);
        this.option.lineSeries[0].data = tempArr;

        this.option.lineSeries[0].yAxisIndex = 0;
        this.option.lineSeries[0].name =
          this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
        this.option.unit = this.setSelectedTempType;

        //处理湿度
        // let wetnessArr = [];
        // this.lineDataList.forEach((element) => {
        //   wetnessArr.push(Math.round(element.wetness));
        // });
        let wetnessArr = this.lineDataList.map((element) => {
          return Math.round(element.wetness);
        });
        console.log("wetnessArr: ", wetnessArr);
        this.option.lineSeries[1].data = wetnessArr;
        this.option.lineSeries[1].yAxisIndex = 1;
        this.option.lineSeries[1].name = this.$t("humidity") + "(%)";

        this.tempConfig = r.data.tempConfig;

        this.wetnessConfig = r.data.wetnessConfig;
        //处理温度参考线
        if (this.tempConfig) {
          const max = this.tempConfig.max;
          console.log("max: ", max);
          const min = this.tempConfig.min;
          console.log("min: ", min);
          this.option.yAxis[0].min = min - 10;
          this.option.yAxis[0].max = this.zoomValue;
          // 添加固定数值的参考线
          this.option.lineSeries[0].markLine.data.push(
            {
              yAxis: max, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: ({ value }) => this.getFormattedTemp(value),
                },
              },
              lineStyle: {
                color: "red",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            },
            {
              yAxis: min, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: ({ value }) => this.getFormattedTemp(value),
                },
              },
              lineStyle: {
                color: "blue",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            }
          );
        } else {
          this.option.yAxis[0].min =
            Math.min(...tempArr) == Infinity ? 0 : Math.min(...tempArr);
          console.log("tempArr: ", JSON.stringify(tempArr));
          console.log("this.option.yAxis[0].min: ", this.option.yAxis[0].min);
          this.option.yAxis[0].max =
            Math.max(...tempArr) == -Infinity ? 30 : Math.max(...tempArr);
          console.log("this.option.yAxis[0].max: ", this.option.yAxis[0].max);
        }

        //处理湿度参考线
        if (this.wetnessConfig) {
          const max = this.wetnessConfig.max;
          console.log("max: ", max);
          const min = this.wetnessConfig.min;
          console.log("min: ", min);
          this.option.yAxis[1].min = min;
          this.option.yAxis[1].max = max;
          // 添加固定数值的参考线
          this.option.lineSeries[1].markLine.data.push(
            {
              yAxis: max, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: max + "%",
                },
              },
              lineStyle: {
                color: "red",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            },
            {
              yAxis: min, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: min + "%",
                },
              },
              lineStyle: {
                color: "blue",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            }
          );
        }
        // else {
        //   this.option.yAxis[1].min = Math.min(...wetnessArr) || 0;
        //   console.log("wetnessArr: ", JSON.stringify(wetnessArr));
        //   console.log("this.option.yAxis[1].min: ", this.option.yAxis[1].min);
        //   this.option.yAxis[1].max = Math.max(...wetnessArr) || 100;
        //   console.log("this.option.yAxis[1].max: ", this.option.yAxis[1].max);
        // }
        this.option.legendTop = 15;
        this.$nextTick(() => {
          this.$refs.chartRef.init(this.option);
        });
      });
    },

    getDetailInfo() {
      getDetailInfoApi({ deviceId: this.deviceId }).then((r) => {
        console.log("获取设备基础信息=====", r);
        this.deviceBaseInfo = r.data;
        this.deviceDataInfo = r.data.deviceDataInfo; //设备最新数据信息
        this.orgInfo = r.data.orgInfo || {};
        this.sceneInfo = r.data.sceneInfo || {};
        this.limits = r.data.limits;
        this.cycleInd = r.data.cycleInd;
        this.rulesEnabled = r.data.rulesEnabled;
        this.startTime = r.data.startTime;
        this.endTime = r.data.endTime;
      });
    },

    getAlarmRules() {
      getAlarmRulesApi({ deviceId: this.deviceId }).then((r) => {
        console.log("获取告警规则=====", r);
        this.form.detailList = r.data.map((item) => {
          if (item.unit === "c" || item.unit === "C") {
            item.unit = "°C";
          } else if (item.unit === "f" || item.unit === "F") {
            item.unit = "°F";
          }
          item.source = String(item.source);
          item.type = String(item.type);
          return item;
          // 保留原始 unit 字段
          //   const originalUnit = item.unit;

          //   // 设置显示的 unit
          //   if (originalUnit === "c") {
          //     item.displayUnit = "°C";
          //   } else if (originalUnit === "f") {
          //     item.displayUnit = "°F";
          //   }

          //   return item;
        });

        this.$forceUpdate();
      });
    },

    downloadExcel() {
      let obj = {
        deviceId: this.deviceId,
        name: this.deviceBaseInfo.name,
        isShipment:false
      };
      console.log("obj: ", obj);

      this.goPages("/loggerInfoExport", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    // edit() {
    //   this.$refs.editRef.showDialog("", this.form.detailList, this.deviceId);
    // },

    // addToScene() {
    //   this.$refs.deviceToSceneRef.showDialog(this.deviceId);
    // },
    convertToTimestamp(dateString) {
      // 使用 moment 解析日期字符串
      const date = moment(dateString, "YYYY-MM-DD HH:mm");
      // 检查解析是否成功
      if (!date.isValid()) {
        console.error("Invalid date string:", dateString);
        return null;
      }
      // 获取时间戳（以毫秒为单位）
      const timestamp = date.valueOf();
      // 如果需要秒级时间戳，可以除以1000
      const timestampInSeconds = Math.floor(timestamp / 1000);
      return timestampInSeconds; // 或者返回 timestampInSeconds 如果你需要的是秒级时间戳
    },
    addToOrganization() {
      this.$refs.deviceToOrganRef.showDialog(this.deviceId);
    },

    toggleLoggerSelection(rows) {
      console.log("rows: ", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.alarmTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.alarmTable.clearSelection();
      }
    },
    handleAlarmSelectionChange(val) {
      console.log("val: ", val.deviceId);
      this.alarmSelection = val;
    },
    setRead() {
      console.log(" this.alarmSelection: ", this.alarmSelection);
      if (this.alarmSelection.length <= 0) {
        this.$message.error(this.$t("please_select_alert_first"));
        return;
      }
      let temp = [];
      this.alarmSelection.forEach((element) => {
        temp.push(element.id);
      });
      let params = {
        idList: temp,
        deviceId: this.deviceId,
      };
      setAlarmReadApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.getAlarmList();
      });
    },
    getAlarmType() {
      selectApi({ type: "AlarmType" }).then((r) => {
        this.alarmType = r.data;
      });
    },
    getAlarmSensor() {
      selectApi({ type: "AlarmSensor" }).then((r) => {
        this.alarmSensor = r.data;
      });
    },
    getAlarmConditions() {
      selectApi({ type: "AlarmConditions" }).then((r) => {
        this.alarmConditions = r.data;
      });
    },
    handleCurrentChange(val) {
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getAlarmList();
    },
    handleResize: _.debounce(function() {
      this.getDetailLine();
    }, 500),
    handleIsShare(val) {
      console.log("val: ", val);
      this.isShare = val;
    },
    showDurationSelector(rule) {
      // console.log("showTempUnitSelector---rule: ", rule);
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2"].includes(rule.type);
    },
    selectExistedRules() {
      this.$refs.existedRulesRef.showDialog(this.deviceId);
    },
    newRules() {
      this.goPages("/createAlert");
    },
    removeRule(item) {
      console.log("item: ", item);
      let params = {
        deviceId: this.deviceId,
        id: item.id,
      };
      deleteAlarmSettingApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("delete_successful"));
        this.getAlarmRules();
      });
    },
    getFormattedTemp(value) {
      return (
        this.$options.filters.convertTemperature(
          Number(value),
          this.setSelectedTempType
        ) + this.setSelectedTempType
      );
    },
    showTemperatureUnit(rule) {
      console.log("showTemperatureUnit---rule: ", JSON.stringify(rule));
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2", "8", "16", "32", "64"].includes(rule.source);
    },
    changeZoomOption(val) {
      console.log("val: ", val);
      switch (val) {
        case "1":
          this.$refs.chartRef.changeHeight("500px");
          this.zoomValue = 30;
          break;
        case "2":
          this.$refs.chartRef.changeHeight("650px");
          this.zoomValue = 40;
          break;
        case "3":
          this.$refs.chartRef.changeHeight("800px");
          this.zoomValue = 60;
          break;

        default:
          break;
      }
    },
    goShipmentDetail() {
      //  this.$refs.addLoggerRef.showDialog();
      // this.goPages("/loggerDetail", { deviceId: row.deviceId });

      let obj = {
        shipmentId: this.deviceBaseInfo.shipmentId,
      };
      console.log("obj: ", JSON.stringify(obj));
      this.goPages("/shipmentDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    attachToShipment() {
      this.$refs.shipmentRef.showDialog(this.deviceId);
    },
    newShipment() {
      this.goPages("/createShipment");
    },
  },
  props: {},
  components: {
    ShareLoggerDialog,
    ChangeLoggerNameDialog,
    ChangeLoggerRemarkDialog,
    StackedAreaChart,
    // ChangeAlarmConditionsDialog,
    TransferDeviceDialog,
    AddDeviceToOrganDialog,
    // AddDeviceToSceneDialog,
    AddCommentDialog,
    SelectExistedRulesDialog,
    AddDeviceToTeamsDialog,
    ShowCanAddShipmentListDialog,
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    chartWidth() {
      return `${window.innerWidth / 2}px`;
    },
  },
  watch: {
    setSelectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getAlarmList();
      this.getDetailLine();
    },

    setSelectedTempType(newVal) {
      if (newVal) {
        this.getDetailLine();
      }
    },
    setSelectedLanguage(newVal) {
      console.log("setSelectedLanguage---newVal: ", newVal);
      this.$i18n.locale = newVal;
      this.getDetailLine();
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    this.timezone = this.setSelectedTimeZone;
    let obj = JSON.parse(decodeURIComponent(this.$route.query.data));
    console.log("obj====: ", obj);
    this.deviceId = obj.deviceId;
    console.log("this.deviceId:", this.deviceId);
    this.clickTab(1);
    this.getDetailInfo();
    this.getDetailSummary();
    this.getAlarmRules();
    this.getAlarmList();
    this.getAlarmType();
    this.getAlarmSensor();
    // this.getAlarmConditions();
    console.log("this.chartWidth: ", this.chartWidth);
    this.$refs.chartRef.init(this.option);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeDestroy() {},

  created() {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 10px 68px 0 38px;
  display: flex;
  flex-direction: column;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .record-style {
      display: flex;
      align-items: center;

      span {
        font-size: 40px;
        font-weight: 500;
        margin-left: 10px;
      }
      img {
        cursor: pointer;
        width: 20px;
        margin: 20px;
      }
      .status-box-green {
        border-radius: 4px;
        padding: 4px 20px;
        background: #2bc885;
        color: white;
      }
      .status-box-gray {
        border-radius: 4px;
        padding: 4px 20px;
        background: #a0a0a0;
        color: white;
      }
    }
    .left-box {
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      width: 48%;

      // .export-box {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: flex-end;
      //   margin-bottom: 20px;
      // }

      .status-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        .point {
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin-right: 4px;
        }
      }
      .time-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        span {
          margin: 0 40px 0 0;
        }
      }
      .function-box {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        background: white;
        padding: 40px 0;
        border-radius: 8px;
        margin-top: 4px;
        .function-item {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          img {
            width: 40px;
          }
          span {
            font-size: 14px;
            color: #1a72f4;
          }
        }
      }
    }
    .right-box {
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      // position: relative;
      // margin-bottom: 4px;

      .operate-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        top: 10px;
        right: 0;
        margin-right: 70px;

        .btn-box {
          display: flex;
          flex-direction: column;
          // margin-right: -20px;

          .btn-style {
            padding: 8px 16px;
            border-radius: 4px;
            font-size: 14px;
            transition: all 0.3s ease;
            background: #1a72f4;
            border-color: #1a72f4;
            border-radius: 4px;
            font-weight: 500;
            margin: 10px 0 10px 10px;
            color: white;
            width: 240px;
            text-align: center;
            padding: 12px 0 12px 20px;
            font-size: 14px;
            display: inline-block;
            line-height: 1;
          }

          .limits-1 {
            background: #1a72f4;
          }

          .limits-0 {
            background: #c0c2cb;
            border-color: #c0c2cb;
            // pointer-events: none; /* 禁止点击事件 */
            cursor: not-allowed; /* 更改光标样式为不可点击 */
          }
        }
      }
    }
  }

  .table-container {
    border-collapse: collapse; /* 合并边框线 */
    border-spacing: 0; /* 去除单元格间隔 */
    // position: absolute; /* 使用相对定位以便绝对定位子元素 */
    // right: 0; /* 重置right属性 */
    // bottom: -5px; /* 重置bottom属性 */
    border-radius: 8px; /* 设置圆角 */
    overflow: hidden; /* 隐藏超出部分，防止圆角被超出内容破坏 */
    background-color: #fff; /* 背景颜色与表格相同以隐藏间隙 */
    border: 1px solid #bbbbbb; /* 边框，与表格边框相同 */
    // height: 400px;
    // top: 150px;
    margin-top: 150px;

    table {
      width: 700px;
    }
    .gray-cell {
      background-color: #f2f2f2;
      padding: 15px;
      // border: 1px solid #bbbbbb;
      border-bottom: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      border-right: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      img {
        width: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .label {
      width: 100px;
      color: black;
      font-weight: 500;
    }

    .white-cell {
      background-color: #fff;
      padding: 15px;
      // border: 1px solid #bbbbbb;
      border-bottom: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      border-right: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      img {
        width: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .shipment-detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn-box {
        text-align: center;
        background: transparent;
        border-radius: 4px;
        border: 1px solid transparent;
        color: #409eff;
        height: 20px;
        line-height: 20px;
        padding: 5px 0;
        cursor: pointer;
      }
      cursor: pointer;
    }
    .shipment-operate {
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn-box {
        text-align: center;
        background: #1a72f4;
        border-radius: 4px;
        border: 1px solid #1a72f4;
        color: white;
        height: 20px;
        line-height: 20px;
        padding: 5px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .measurements {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .label {
      font-weight: 500;
      font-size: 15px;
    }
    .value {
      font-size: 30px;
    }
    .measurements-title {
      background: #e8e9ef;
      width: 100%;
      height: 50px;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .measurements-box {
      background: white;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .measurements-up {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 20px;

        .left1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          .left1_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
        .left2 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          .left2_2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 26px;
              margin: 0 10px 0 0;
            }
          }
        }

        .right1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          margin-left: 40px;

          .right1_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
        .right2 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          .right2_2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
      }

      .h-line {
        width: 100%;
        height: 1px;
        background: #ececec;
      }
      .measurements-down {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        margin-left: 20px;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;

          .left_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          margin-left: 40px;
          .right_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 26px;
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }

  .alert-box {
    background: white;
    padding: 20px;
    border-radius: 8px;

    .table-title {
      margin: 10px 0;
    }

    .alert-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
      span {
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
  .rules-box {
    margin: 10px 0;
    background: white;
    padding: 20px;
    border-radius: 8px;

    .rules-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // align-items: center;
      margin-bottom: 20px;
      .left {
        display: flex;
        flex-direction: column;
        .font-light {
          color: #9b9b9b;
          font-size: 14px;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        .el-button--primary {
          background: #1a72f4;
          border-color: #1a72f4;
          height: 40px;
        }
      }
    }
    span {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .echart-box {
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;

    span {
      font-size: 28px;
      font-weight: 500;
    }
    .zoom-style {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .tab-box {
          margin: 10px 0;
          display: flex;
          background: white;
          flex-direction: row;
          align-items: center;
          line-height: 40px;
          height: 20px;
          padding-right: 10px;
          .zoom-box {
            margin-left: 20px;
            font-size: 16px;
          }
        }
        .tab-item {
          font-size: 14px;
          text-align: center;
          color: white;
          justify-content: center;
          background: #bbbaba;
          height: 24px;
          line-height: 24px;
          width: 60px;
          border-radius: 2px;
          margin: 0 5px;
          cursor: pointer;
        }

        .is-active {
          background: #1a72f4;
          font-size: 16px;
          font-weight: Normal;
          text-align: center;
          color: #ffffff;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .date-tip {
          font-size: 18px;
          margin: 0 10px;
        }
      }
    }
    .chart {
      position: relative;
      top: 10px;
      .additional-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        top: 0;
        height: 14px;
        line-height: 14px;

        margin-top: 18px;
        img {
          width: 15px;
          height: 15px;
        }
        .all-null {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          span {
            color: #333333;
            font-size: 14px;
            margin-top: 2px;
          }
        }
        .not-null {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .high {
            color: #333333;
            margin: 0 5px;
            margin-top: 2px;
            span {
              color: #fc5c5b;
              font-size: 14px;
              margin-top: 2px;
            }
          }
          .low {
            color: #333333;
            margin: 0 5px;
            margin-top: 2px;
            span {
              color: #6464ff;
              font-size: 14px;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
  .statistics-box {
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;

    span {
      font-size: 28px;
      font-weight: 500;
    }
    table {
      width: 100%;
      margin-bottom: -5px;
      margin: 20px 0;
    }
    .white-cell {
      background: white;
      width: 25%;
      padding: 10px;
      border: 1px solid #bbbbbb;
      text-align: center;
    }
  }
  .summary-box {
    background: white;
    padding: 20px;
    border-radius: 8px;

    span {
      font-size: 28px;
      font-weight: 500;
    }

    table {
      width: 100%;
      margin-bottom: -5px;
      margin: 20px 0;
    }
    .white-cell {
      background: white;
      width: 33.33%;
      padding: 10px;
      border: 1px solid #bbbbbb;
      text-align: center;
    }
  }
}
.chart {
  width: 97%;
  color: white;
}
.comment-box {
  display: flex;
  justify-content: center;

  .add-comment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 32px;
  }
}
.rule-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(187, 187, 187, 1);
  padding: 5px 10px;
  height: 59px;
  line-height: 59px;
  border-radius: 5px;
  margin-left: -120px;
  img {
    width: 33px;
    height: 33px;
  }
  .selects-container {
    display: flex;
    gap: 8px;
    align-items: center;
    .select-item {
      width: 220px;
      height: 47px;
      line-height: 47px;
    }
    .input-item {
      width: 100px;
      height: 47px;
      line-height: 47px;
    }
  }

  /deep/.el-select .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner:-ms-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::placeholder {
    color: #606266;
  }
}
/deep/.el-select .el-input__inner {
  // height: 30px;
  // width: 80px;
}
</style>

<!--
 * @Author       : JiangChao
 * @Date         : 2024-07-28 18:57:20
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-16 22:36:26
 * @Description  : 移交设备
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"

  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("transfer_device") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="form"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="0px">
          <el-input
            clearable
            v-model="form.subject"
            autocomplete="off"
            :placeholder="$t('phone_email')"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deviceTransferApi } from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      deviceId: "",
    };
  },
  methods: {
    // show() {
    //   this.showDialog();
    // },
    showDialog(id) {
      this.deviceId = id;
      this.dialogFormVisible = true;
      this.form = {};
    },

    handelOk() {
      let params = {
        deviceId: this.deviceId,
        ...this.form,
      };
      deviceTransferApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
        // this.$emit("refresh");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>

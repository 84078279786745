<!--
 * @Author       : JiangChao
 * @Date         : 2024-11-05 10:03:18
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-05 10:29:04
 * @Description  : 
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="85%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("shipments") }}
      </div>
    </template>

    <!-- 表格 -->
    <el-table
      :data="shipmentList"
      style="width: 100%"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      @selection-change="handleShipmentSelectionChange"
      :empty-text="$t('no_data')"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column :label="$t('table_key_shipment_name')" prop="name">
        <template v-slot:default="scope">
          <span class="organ-detail" @click="goShipmentDetail(scope.row)">
            {{ scope.row.name }}</span
          >
        </template>
      </el-table-column>

      <el-table-column :label="$t('shipment_id')" prop="shipmentId">
      </el-table-column>
      <el-table-column :label="$t('alert_status')" prop="alarmNum">
        <template v-slot:default="scope">
          <div v-if="scope.row.alarmInd == 1" class="alert-style">
            <img src="@/assets/ic_alert.png" width="15" /><span>{{
              scope.row.alarmNum
            }}</span>
          </div>
          <div v-else-if="scope.row.alarmInd == 0" class="alert-style">
            <img src="@/assets/ic_no_alert.png" width="15" /><span>{{
              $t("no_alert")
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table_key_shipment_status')"
        prop="status"
        width="130px"
      >
        <template v-slot:default="scope">
          <div class="status-style">
            <div
              class="point"
              :style="
                scope.row.status == '1'
                  ? 'background: #02C06F;'
                  : 'background: #ed6e36;'
              "
            ></div>
            <span v-if="scope.row.status == '1'">{{ $t("tab_on_going") }}</span>

            <span v-if="scope.row.status == '0'" style="color: #ed6e36">{{
              $t("tab_not_start")
            }}</span>
            <span v-if="scope.row.status == '9'" style="color: #ed6e36">{{
              $t("tab_ended")
            }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('start_time')" prop="startTime">
        <template v-slot:default="scope">
          {{
            scope.row.startTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('end_time')" prop="endTime">
        <template v-slot:default="scope">
          {{
            scope.row.endTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('duration')" prop="duration">
        <template v-slot:default="scope">
          {{ scope.row.duration }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('logger')"
        prop="deviceInfoList"
        width="220px"
      >
        <template v-slot:default="scope">
          <span
            v-for="device in scope.row.deviceInfoList"
            :key="device.deviceId"
            @click="goDeviceDetail(device)"
            class="device-detail"
          >
            {{ formatDeviceName(device) }}<br />
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('note')" prop="remark" min-width="150">
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: center"
      background
      @current-change="handleCurrentChange"
      :current-page="Number(this.pageNum)"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="Number(this.pageSize)"
      layout="total,pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-form
      :model="form"
      status-icon
      ref="resetRef"
      label-width="130px"
      class="demo-ruleForm"
    >
      <div class="top">
        <div class="right">
          <el-button
            style="width: 145px; border: 1px solid #1a72f4; color: #1a72f4"
            plain
            @click="dialogFormVisible = false"
            >{{ $t("cancel") }}</el-button
          >
          <el-button
            style="width: 145px; background: #1a72f4; border: 1px solid #1a72f4"
            type="primary"
            @click="handelOk"
          >
            {{ $t("confirm") }}</el-button
          >
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  getCanAddShipmentListApi,
  addDeviceToShipmentListApi,
} from "@/api/api.js";

export default {
  name: "ShowCanAddShipmentListDialog",
  data() {
    return {
      dialogFormVisible: false,
      shipmentList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      form: {},
      selectedRows: [], // 存储已勾选行的数据
      isChina: true,
      deviceId: "",
    };
  },
  methods: {
    showDialog(id) {
      this.deviceId = id;
      this.dialogFormVisible = true;
      this.getCanAddShipmentList();
    },
    handelOk() {
      if (this.selectedRows.length > 1) {
        this.$message.error(this.$t("each_time_add_one_shipment"));
        return;
      }

      let params = {
        deviceId: this.deviceId,
        shipmentId: this.shipmentId,
      };

      addDeviceToShipmentListApi(params).then((r) => {
        console.log("r: ", r);

        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
        this.$emit("ok");
      });
    },

    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // this.queryParams.page.pageIndex = val;
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getCanAddShipmentList();
    },
    getCanAddShipmentList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        ...this.form,
      };

      getCanAddShipmentListApi(params).then((response) => {
        this.shipmentList = response.data.records;
        this.total = response.data.total;

        // 保存当前的选择状态
        const currentSelection = this.$refs.loggerTable.selection;
        // 确保在 DOM 更新后执行
        this.$nextTick(() => {
          // 清除现有的选择状态，因为数据已经改变
          // this.$refs.loggerTable.clearSelection();

          // 重新设置勾选状态
          currentSelection.forEach((row) => {
            const foundRow = this.shipmentList.find(
              (sensorRow) => sensorRow.deviceId === row.deviceId
            );
            if (foundRow) {
              this.$refs.loggerTable.toggleRowSelection(foundRow, true);
              console.log("Row selected:", foundRow);
            }
          });
        });
      });
    },

    handleShipmentSelectionChange(val) {
      console.log("val: ", val);
      this.selectedRows = val;
    },
    rowSelectable(row) {
      console.log("row: ", row);
      return row.enabled === 1;
    },
    handleSortChange({ column, prop, order }) {
      console.log("handleSortChange--order: ", order);
      console.log("handleSortChange--column: ", column);
      console.log("handleSortChange--prop: ", prop);
      // if (prop === "battery.timestamp") {
      //   this.sortData(order);
      // }
    },
    sortData(order) {
      let reverse = order === "descending" ? -1 : 1;
      this.shipmentList.sort((a, b) => {
        // 对于 Unix 时间戳，我们直接使用数字比较
        return reverse * (a.timestamp - b.timestamp);
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
  },
  created() {},
};
</script>

<style scoped lang="less">
.measurements-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.alert-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 5px;
  }
}
.demo-ruleForm {
  margin-top: 20px;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 0;
  .left {
    display: flex;
    flex-direction: row;
  }
  .right {
    position: absolute;
    right: 0;
    margin-right: 20px;
  }
}

.status-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .point {
    width: 6px;
    height: 6px;

    border-radius: 3px;
    margin-right: 4px;
  }
}
</style>

<!--
 * @Author       : JiangChao
 * @Date         : 2024-07-18 13:32:38
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-16 22:34:49
 * @Description  : 添加设备到组织
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="30%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("add_device_to_the_organization") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="form"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('organ_name')">
          <el-select v-model="form.orgId" filterable :placeholder="$t('please_enter_keyword')" >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deviceDetailToOrgApi, orgDeviceJoinListApi } from "@/api/api.js";

export default {
  name: "AddDeviceToOrganDialog",
  data() {
    return {
      dialogFormVisible: false,
      deviceId: "",
      form: {},
      options: [],
      keyword: "",
    };
  },
  methods: {
    showDialog(id) {
      this.dialogFormVisible = true;
      this.deviceId = id;
      this.getOrgDeviceJoinList();

      this.form = {};
    },
    handelOk() {
      // if (this.loggerSelection.length <= 0) {
      // this.$message.error(this.$t('at_least_select_one_device'));
      //   return;
      // }
      // let temp = [];
      // this.loggerSelection.forEach((element) => {
      //   temp.push(element.deviceId);
      // });
      let params = {
        deviceId: this.deviceId,
        ...this.form,
      };
      deviceDetailToOrgApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
      });
    },

    getOrgDeviceJoinList() {
      let params = {
        keyword: this.keyword,
      };
      orgDeviceJoinListApi(params).then((r) => {
        console.log("=====", r);
        this.options = r.data;
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less">
</style>

<!--
 * @Author       : JiangChao
 * @Date         : 2024-11-04 21:40:09
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-04 22:27:52
 * @Description  : Teams Access
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="30%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px; color: #303133">
        {{ $t("teams_with_access") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="form"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('organ_name')">
          <el-radio-group v-model="form.orgId">
            <el-radio
              v-for="item in options"
              :key="item.value"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deviceDetailToOrgApi, orgDeviceJoinListApi } from "@/api/api.js";

export default {
  name: "AddDeviceToTeamsDialog",
  data() {
    return {
      dialogFormVisible: false,
      deviceId: "",
      form: {
        orgId: "", // 初始化为一个空字符串
      },
      options: [],
      keyword: "",
    };
  },
  methods: {
    showDialog(deviceId, orgId) {
      this.dialogFormVisible = true;
      this.deviceId = deviceId;
      this.form.orgId = orgId; // 默认选中传入的 orgId
      this.getOrgDeviceJoinList();
    },
    handelOk() {
      if (!this.form.orgId) {
        this.$message.error(this.$t("please_select_one_organization"));
        return;
      }
      let params = {
        deviceId: this.deviceId,
        orgId: this.form.orgId,
      };
      deviceDetailToOrgApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
        this.$emit("ok");
      });
    },
    getOrgDeviceJoinList() {
      let params = {
        keyword: this.keyword,
      };
      orgDeviceJoinListApi(params).then((r) => {
        console.log("=====", r);
        this.options = r.data;

        // 设置默认选中的值
        if (this.form.orgId) {
          this.form.orgId =
            this.options.find((option) => option.value === this.form.orgId)
              ?.value || "";
        }
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>

<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-23 09:54:40
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-16 22:36:02
 * @Description  : 
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"
  >
   <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("sharing_devices") }}
      </div>
    </template>
    <div>
      <div class="share-box">
        <div style="margin-bottom:20px">{{ $t("member_account") }}</div>
        <el-form
          :model="form"
          status-icon
          ref="resetRef"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label-width="0px">
            <el-input
              clearable
              v-model="form.contact"
              autocomplete="off"
              :placeholder="placeholderTip"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="share-bottom">
          <el-button type="primary" class="btn-share" @click="shareDevice">
            {{ $t("share") }}</el-button
          >
        </div>
      </div>

      <el-divider></el-divider>

      <div class="shared-box">
        <div>
          {{ $t("shared_members") }}
        </div>
        <el-form
          :model="form2"
          status-icon
          ref="resetRef"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label-width="0px">
            <el-checkbox-group v-model="form2.userIds">
              <el-checkbox
                v-for="user in users"
                :key="user.userId"
                :label="user.userId"
                >{{ user.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div class="share-bottom">
          <el-button type="primary" class="btn-share" @click="cancelShare">
            {{ $t("cancel_share") }}</el-button
          >
        </div>
      </div>
    </div>
    <el-divider></el-divider>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false" class="btn-share-plain">{{
        $t("cancel")
      }}</el-button>
      <el-button
        type="primary"
        class="btn-share"
        @click="dialogFormVisible = false"
        >{{ $t("confirm") }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {
  loggerSharingApi,
  shareUsersApi,
  cancelLoggerSharingApi,
} from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      form: {
        contact: "",
      },
      dialogFormVisible: false,
      placeholderTip: "",
      deviceId: "",
      isChina: true,
      form2: {
        userIds: [],
      },
      // selectedUsers: [], // 用来保存被选中的用户名称
      users: [],
    };
  },
  methods: {
    // show(id) {
    // this.showDialog(id);
    // },
    showDialog(id) {
      this.dialogFormVisible = true;
      this.deviceId = id;
      console.log("this.deviceId: ", this.deviceId);
      this.form = {};
      this.getShareUsers();
    },
    shareDevice() {
      if (!this.form.contact) {
        this.$message.error(this.$t("username_cannot_be_empty"));
        return;
      }
      let params = {
        ...this.form, //用户账号(取消分享设备时，此处是userId)
        deviceId: this.deviceId,
        limits: 0, //权限，1：编辑 0：查看
        userIds: [], //取消分享时的用户id列表
      };
      console.log("params: ", params);

      loggerSharingApi(params).then((r) => {
        console.log("=====", r);

        this.$message.success(this.$t("operation_successful"));
        this.$emit("isShare", true);
        this.dialogFormVisible = false;
        // this.$emit("refresh");
      });
    },
    getShareUsers() {
      let params = {
        deviceId: this.deviceId,
      };

      shareUsersApi(params).then((r) => {
        console.log("=====", r);
        this.users = r.data;

        // this.dialogFormVisible = false;
      });
    },
    cancelShare() {
      //  if (this.form.userIds) {
      //   this.$message.error(this.$t("username_cannot_be_empty"));
      //   return;
      // }
      console.log("this.form2.userIds: ", this.form2.userIds);

      let params = {
        deviceId: this.deviceId,
        limits: 0, //权限，1：编辑 0：查看
        ...this.form2,
      };
      console.log("params: ", params);

      cancelLoggerSharingApi(params).then((r) => {
        console.log("=====", r);

        this.$message.success(this.$t("operation_successful"));

        this.dialogFormVisible = false;
        this.$emit("isShare", false);
      });
    },
    handelOk() {},
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;

    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    console.log("this.isChina: ", this.isChina);
    this.placeholderTip = this.isChina
      ? this.$t("phone_email")
      : this.$t("email");
  },
  created() {},
};
</script>

<style scoped lang="less">
.share-box {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  .share-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .btn-share {
      width: 120px;
      background: #1a72f4;
      border: 1px solid #1a72f4;
    }
  }
}
.shared-box {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  .share-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .btn-share {
      width: 120px;
      background: #1a72f4;
      border: 1px solid #1a72f4;
    }
  }
}
.dialog-footer {
  margin-top: -50px;
  padding-left: 50px;
  padding-right: 50px;
  .btn-share-plain {
    width: 120px;
    border: 1px solid #1a72f4;
    color: #1a72f4;
  }
  .btn-share {
    width: 120px;
    background: #1a72f4;
    border: 1px solid #1a72f4;
  }
}
</style>
